import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";

import { Header } from "../components/Header/Header";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import logoWhite from "../images/logo-white.png";
import { navigate } from "gatsby";
import { Link } from "gatsby";
import { Footer } from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";

const Contact = () => {
  const userName =
    typeof window !== "undefined" && localStorage.getItem("name");
  const [signed, setSigned] = useState(false);

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);

  useEffect(() => {
    if (loginElement.items.status) {
      setSigned(true);
    }
  }, [loginElement]);

  return (
    <Layout signed={signed}>
      <div className="bg-[#f2f2f2]">
        <div className="mb-[40px]">
          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="w-full">
              <div className="col-span-8">
                <iframe
                  src="https://umgcventures.kustomer.help/contact/support-HJgglXS_o"
                  width={"100%"}
                  className="w-full h-[860px] md:h-[840px] lg:h-[810px] rounded-2xl"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
          </section>

          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="bg-white rounded-2xl p-[30px] w-full">
              <div className="col-span-8 flex justify-center items-center">
                <EnvelopeIcon className="w-10 h-10 mr-4" />
                <p className="text-[20px]">
                  Whether looking for information on programs, admissions, or
                  enrollments, please{" "}
                  <Link
                    to="/contact"
                    className="ff-cg--semibold text-[#da1a32] underline"
                  >
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
export { Head } from "../components/Layout/Head";
